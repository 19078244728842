import inputMask from 'imask';
import IbanValidator from 'iban';
import { getElementChildren } from '../../utility/Dom';

const FORM_HIDDEN_CLASS = 'u-hidden';
const IBAN_INPUT_SELECTOR = '[data-js="iban-input"]';
const PHONE_INPUT_SELECTOR = '[data-js="phone-input"]';

export default class Form {
    constructor( form ) {
        this.container = form;
        this.init();
    }

    init() {
        let submitButton = this.container.querySelector( '.submit' );
        if ( this.container.contains( this.container.querySelector( '#mf-pension-insurance' ) ) ) {
            this.initPensionInsurance();
        }

        if ( this.container.contains( this.container.querySelector( '#mf-insurance-begin' ) ) ) {
            this.initInsuranceBegin();
        }

        if ( this.container.contains( this.container.querySelector( '#mf-company' ) ) ) {
            this.initCompany();
        }

        if ( this.container.contains( this.container.querySelector( '#if-send-information' ) ) ) {
            this.initSendInformation();
        }

        if ( this.container.contains( this.container.querySelector( '#cf-contact' ) ) ) {
            this.initContact();
        }

        if ( this.container.contains( this.container.querySelector( '#rf-recruitment' ) ) ) {
            this.initRecruitment();
        }

        if ( this.container.contains( this.container.querySelector( '#ff-form' ) ) ) {
            this.initFeedback();
        }

        if ( this.container.contains( this.container.querySelector( '#mf-marketing-source-comment-container' ) ) ) {
            this.initMarketingSourceComment();
        }

        if ( this.container.contains( this.container.querySelector( IBAN_INPUT_SELECTOR ) ) ) {
            this.initIbanFormatterAndValidation();
        }

        if ( this.container.contains( this.container.querySelector( PHONE_INPUT_SELECTOR ) ) ) {
            this.initPhoneFormatterAndValidation();
        }

        this.initMessageErrorList();

        // disable submit button after send to prevent double sent forms
        this.container.addEventListener('submit', function (event) {
            submitButton.disabled = true;
            submitButton.textContent = 'Sendet...';

            setTimeout(() => {
                submitButton.disabled = false;
                submitButton.textContent = 'Absenden';
            }, 10000);
        });
    }

    initFeedback() {
        let triggers = this.container.querySelectorAll( '#ff-member-0, #ff-member-1' );
        let isMemberTrigger = this.container.querySelector( '#ff-member-0' );
        let extendTarget = this.container.querySelector( '#ff-is-member' );
        let requirementTargets = extendTarget.querySelectorAll( 'select' );

        // show additional fields if member, or hide if not
        function handleTrigger() {
            this.toggleVisibilityByTrigger( extendTarget, isMemberTrigger.checked );

            for ( let target of requirementTargets ) {
                this.toggleRequirementByTrigger( target, isMemberTrigger.checked );
            }
        }

        for ( let input of triggers ) {
            input.addEventListener( 'change', handleTrigger.bind( this ), true );
        }

        handleTrigger.call( this );
    }

    initContact() {
        const triggers = this.container.querySelectorAll( '#cf-member-0, #cf-member-1' );
        const extendTrigger = this.container.querySelector( '#cf-member-0' );
        const extendTarget = this.container.querySelector( '#cf-is-member' );
        const requirementTrigger = this.container.querySelector( '#cf-member-0' );
        const requirementTargets = extendTarget.querySelectorAll( 'input' );

        // show additional fields if member, or hide if not
        function handleTrigger() {
            this.toggleVisibilityByTrigger( extendTarget, extendTrigger.checked );

            for ( let target of requirementTargets ) {
                this.toggleRequirementByTrigger( target, requirementTrigger.checked );
            }
        }

        for ( let input of triggers ) {
            input.addEventListener( 'change', handleTrigger.bind( this ), true );
        }

        handleTrigger.call( this );
    }

    initRecruitment() {
        const trigger = this.container.querySelector( '#rf-no-insurance-number' );
        const extendTarget = this.container.querySelector( '#rf-insurance-alternative' );
        const requirementTargets = getElementChildren( extendTarget, 'recruitment-form-insurance-alt-input' );
        const targetInsuranceNumber = this.container.querySelector( '#rf-recruiter-insurance-number' );

        function handleTrigger() {

            this.toggleVisibilityByTrigger( extendTarget, trigger.checked );

            for ( let target of requirementTargets ) {
                this.toggleRequirementByTrigger( target, trigger.checked );
            }

            this.toggleRequirementByTrigger( targetInsuranceNumber, !trigger.checked );
            this.toggleAbilityByTrigger( targetInsuranceNumber, !trigger.checked );
        }

        // bind events
        trigger.addEventListener( 'change', handleTrigger.bind( this ), true );

        // init
        handleTrigger.call( this );
    }

    initPensionInsurance() {
        const trigger = this.container.querySelector( '#mf-no-pension-number' );
        const targetFieldset = this.container.querySelector( '#mf-pension-alternative' );
        const targetAlternativeFields = getElementChildren( targetFieldset, 'member-form-alt-input' );
        const targetPensionNumber = this.container.querySelector( '#mf-pension-number' );

        let alternativeIsVisible;
        let that = this;

        function handleTrigger( that ) {

            // show fieldset when trigger checkbox is checked, else hide
            that.toggleVisibilityByTrigger( targetFieldset, trigger.checked );

            // disable pension number field when trigger checkbox is checked, else enable
            that.toggleAbilityByTrigger( targetPensionNumber, !trigger.checked );

            // set all fields of pension number alternative to required when trigger checkbox is checked, else not
            alternativeIsVisible = !targetFieldset.classList.contains( FORM_HIDDEN_CLASS );
            for ( let item of targetAlternativeFields ) {
                that.toggleRequirementByTrigger( item, alternativeIsVisible );
            }

            // set pension number field to required when trigger checkbox is checked, else not required
            that.toggleRequirementByTrigger( targetPensionNumber, !alternativeIsVisible );
        }

        handleTrigger( that );

        trigger.addEventListener( 'click', function() {
            handleTrigger( that );
        }.bind( this ), true );
    }

    initMarketingSourceComment() {
        const trigger = this.container.querySelector( '#mf-marketing-source' );
        const targetContainer = this.container.querySelector( '#mf-marketing-source-comment-container' );

        let that = this;

        function handleTrigger( that ) {

            // show fieldset when trigger checkbox is checked, else hide
            if ( trigger.value.length > 0 ) {
                that.toggleVisibilityByTrigger( targetContainer, true );
            }
            else {
                that.toggleVisibilityByTrigger( targetContainer, false );
            }
        }

        handleTrigger( that );

        trigger.addEventListener( 'change', function() {
            handleTrigger( that );
        }.bind( this ), true );
    }

    initInsuranceBegin() {
        const trigger = this.container.querySelector( '#mf-insurance-begin-1' );
        const targetDateFields = this.container.querySelectorAll( '#mf-insurance-begin-date select' );
        const radiosInsuranceBegin = this.container.querySelectorAll( '#mf-insurance-begin input[type="radio"]' );

        let that = this;

        function handleTrigger( that ) {

            for ( let item of targetDateFields ) {

                // disable date fields when trigger radio is checked, else enable
                that.toggleAbilityByTrigger( item, trigger.checked );

                // set date fields to required when trigger radio is checked, else not required
                that.toggleRequirementByTrigger( item, trigger.checked );
            }
        }

        // init
        handleTrigger( that );

        // bind
        for ( let item of radiosInsuranceBegin ) {
            item.addEventListener( 'change', function() {
                handleTrigger( that );
            }.bind( this ), true );
        }
    }

    initCompany() {
        const trigger = this.container.querySelector( '#mf-occupation' );
        const targetFieldset = this.container.querySelector( '#mf-company' );
        const targetFields = getElementChildren( this.container, 'member-form-company-field' );
        let selectedValue;
        let showTarget;

        selectedValue = trigger.options[ trigger.selectedIndex ].value;

        let that = this;

        function handleTrigger( that ) {
            selectedValue = trigger.options[ trigger.selectedIndex ].value;

            if ( selectedValue === 'Arbeitnehmer/in' || selectedValue === 'Auszubildende/r' ) {
                showTarget = true;
            }
            else {
                showTarget = false;
            }

            // show fieldset when trigger value is selected, else hide
            that.toggleVisibilityByTrigger( targetFieldset, showTarget );

            // set company fields to required when parent fieldset is shown, else not required
            for ( let item of targetFields ) {
                that.toggleRequirementByTrigger( item, showTarget );
            }
        }

        handleTrigger( that );

        trigger.addEventListener( 'change', function() {
            handleTrigger( that );
        }.bind( this ), true );
    }

    initSendInformation() {
        const trigger = this.container.querySelector( '#if-contact-policy' );
        const targetFieldset = this.container.querySelector( '#if-reception-address' );
        const targetFields = getElementChildren(this.container, 'information-form-reception-address-field' );
        let showTarget;

        let that = this;

        function handleTrigger( that ) {
            showTarget = trigger.checked;

            // show fieldset when trigger value is selected, else hide
            that.toggleVisibilityByTrigger( targetFieldset, showTarget );

            // set company fields to required when parent fieldset is shown, else not required
            for ( let item of targetFields ) {
                that.toggleRequirementByTrigger( item, showTarget );
            }
        }

        handleTrigger( that );

        trigger.addEventListener( 'click', function() {
            handleTrigger( that );
        }.bind( this ), true );
    }

    initMessageErrorList() {
        const errorLinks = this.container.querySelectorAll( '.message__error-link' );

        // workaround for FF to trigger focus and selection on targeted input element
        for ( let item of errorLinks ) {
            item.addEventListener( 'click', function( e ) {
                e.preventDefault();

                let hash = e.target.href.substring( e.target.href.indexOf( '#' ) + 1 );
                let input = document.getElementById( hash );

                input.focus();
            }.bind( this ), false );
        }
    }

    initIbanFormatterAndValidation() {
        const ibanInput = this.container.querySelector( IBAN_INPUT_SELECTOR );

        inputMask(ibanInput, {
            mask: '#### #### #### #### #### #### #### ####',
            definitions: {
                '#': /^\w+$/
            },
            prepare: function (str) {
                return str.toUpperCase();
            }
        });

        ibanInput.addEventListener('input', () => {
            ibanInput.classList.remove( 'error' );

            if ( false === IbanValidator.isValid( ibanInput.value ) ) {
                ibanInput.classList.add( 'error' );
            }
        });
    }

    initPhoneFormatterAndValidation() {
        const phoneInput = this.container.querySelector( PHONE_INPUT_SELECTOR );

        phoneInput.addEventListener('input', () => {
            let phoneNumber = phoneInput.value;

            if ( 0 === phoneNumber.indexOf('0') ) {
                phoneInput.value = '+49' + phoneNumber.substring(1);

                this.moveCursorToEnd( phoneInput );
            }
        });

        inputMask(phoneInput, {
            mask: '+## ### ### ##########',
            definitions: {
                '#': /^[0-9]$/,
                '+': /^\+|^[0-9]$/
            },
        });
    }

    toggleVisibilityByTrigger( target, showTarget ) {
        if ( showTarget ) {
            target.classList.remove( FORM_HIDDEN_CLASS );
        }
        else {
            target.classList.add( FORM_HIDDEN_CLASS );
        }
    }

    toggleRequirementByTrigger( target, requireTarget ) {
        target.required = false;

        if ( requireTarget ) {
            target.required = true;
        }
    }

    toggleAbilityByTrigger( target, enableTarget ) {
        target.disabled = true;

        if ( enableTarget ) {
            target.disabled = false;
        }
    }

    moveCursorToEnd( el ) {
        if ( 'number' === typeof el.selectionStart ) {
            el.selectionStart = el.selectionEnd = el.value.length;
        }
        else if ( 'undefined' !== typeof el.createTextRange ) {
            el.focus();
            const range = el.createTextRange();
            range.collapse( false );
            range.select();
        }
    }
}
